@use "../../../../config/variables.scss" as global;
.aboutBg {
  background: url("../../../../assets/images/campbg.png");
  height: 140vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 992px) {
    display: none;
  }
}
.aboutBgSm {
  display: none;
  @media screen and (max-width: 992px) {
    display: block;
  }
}
.aboutusImage {
  width: 100%;
  height: 65vh;
  background-size: cover !important;
  background: url("../../../../assets/images/AboutusMobile.png");
  background-repeat: no-repeat;
}
.textContainer {
  padding-top: 80px;
  @media screen and (min-width: 1600px) {
    width: 80%;
  }
  @media screen and (max-width: 992px) {
    padding-top: 10px;
    width: 90%;
  }
}
.aboutus-heading {
  height: 100%;
  color: #ffc127;
  font-size: 30px;
  font-family: global.$Bold;
}
.aboutus-descSm {
  padding-left: 15px;
  color: #000000;
  font-family: global.$Regular;
  font-size: 13px;
}
.aboutus-desc {
  font-family: global.$Regular;
  color: #ede5bd;
  font-size: 13px;
  @media screen and (min-width: 992px) {
    font-size: 14px;
  }
  @media screen and (min-width: 1200px) {
    font-size: 15px;
  }
  @media screen and (min-width: 1400px) {
    font-size: 18px;
  }
}
