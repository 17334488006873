@use "../../config/variables.scss" as vars;

.transactionItem1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}

.transactionTxt1 {
  font-size: 15px;
  font-family: vars.$Medium;
  color: #656565;
}

.transactionTxt2 {
  font-size: 15px;
  font-family: vars.$SemiBold;
}

.transactionTxt3 {
  font-size: 15px;
  font-family: vars.$SemiBold;
  color: blue;
  text-decoration: underline;
  text-transform: uppercase;
  cursor: pointer;
}
