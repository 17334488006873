@use "../../config/variables.scss" as vars;

.ViewBtn {
  color: rgb(22, 22, 205);
  cursor: pointer;
}

.ViewBtn:hover {
  text-decoration: underline;
}

.settleStatus {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-size: 18px;
  font-family: vars.$Bold;
}
