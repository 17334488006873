.ImagePicker-backgroud {
  border-radius: 6px;
  border: 2px dashed gray;
  background-color: #fff;
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  text-align: center;
  color: grey;
  padding: 25px;
  position: relative;
  &.small {
    height: 170px;
    padding: 20px 10px;
  }
}
.imagePickerImg {
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
  object-fit: cover;
}

// -----------HorizontalLineLoader----------------

.loader {
  width: 100%;
  height: 1px;
  position: relative;
}

.fixed-line {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.moving-line {
  position: absolute;
  top: 0;
  height: 100%;
  width: 0%;
  animation: progress 0.7s linear infinite;
}

@keyframes progress {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
