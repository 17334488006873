.ticketScreen-PageHeaderBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.actCol{
border-left: 1px solid gray;
padding-left: 20px;
}

.ticketScreen-table{
  width: 100%;
}
.ticketScreen-table2{
  table-layout: fixed;
  margin-top: -16px;
}