@use "../../config/variables.scss" as vars;
.Footer {
  background-color: #0e6c6c;
  font-family: vars.$Regular;
  padding: 50px 0px 0px 0px;
}
.Footer-logo {
  img {
    width: 100%;
  }
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 576px) {
    border-bottom: 1px solid #f9da7f4d;
    padding-bottom: 20px;
    img {
      width: 50%;
    }
  }
}

.Footer-text1 {
  color: #fff;
  font-size: 14px;
  @media screen and (max-width: 576px) {
    border-bottom: 1px solid #f9da7f4d;
    padding-bottom: 20px;
    padding-top: 20px;
    margin-bottom: 20px;
  }
}

.Footer-text2 {
  color: #f9da7f;
  font-size: 20px;
  margin-bottom: 10px;
  font-family: vars.$SemiBold;
}

.Footer-text3 {
  color: #fff;
  font-size: 14px;
  padding-bottom: 20px;
  text-align: left;
  cursor: pointer;
}

.Footer-copyRightTxt {
  color: #cecece;
  font-size: 12px;
  text-align: center;
  padding-bottom: 20px;
}

.Footer-Box1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  margin-top: 20px;
  font-size: 30px;
  @media screen and (max-width: 576px) {
    border-top: 1px solid #f9da7f4d;
    justify-content: center;
    padding-top: 20px;
  }
}

.Footer-Box2 {
  @media screen and (max-width: 576px) {
    border-top: 1px solid #f9da7f4d;
    justify-content: center;
    padding-top: 20px;
  }
}
