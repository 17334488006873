//colors
$primary: #08493e;
$primary1: #ffc127;
$secondary: #dddded;
$secondary1: #08493e;
$tertiary: #188a8a;
$tertiary1: #f9da7f;
$quartenery: "#D4D2E8";
$quartenery1: #42d3e1;
$black: "#000";
$white: #ffffff;
$light: #e9f5ec;
$light_red: #ffe7e3;
//fonts
$Regular: "DMSans-Regular";
$Italic: "DMSans-Italic";
$Bold: "DMSans-Bold";
$ExtraBold: "DMSans-ExtraBold";
$SemiBold: "DMSans-SemiBold";
$Medium: "DMSans-Medium";

$fancy: "Mistrully";
$BarlowCondensedBold: "BarlowCondensed-Bold";
$BarlowRegular: "Barlow-Regular";
$BarlowBold: "Barlow-Bold";
$Montserrat_SemiBold: "Montserrat-SemiBold";
