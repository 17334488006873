@use ".././../config/variables.scss" as vars;

.itemCardHead {
  width: 95%;
  border-radius: 8px;
  margin-bottom: 35px;
  position: relative;
}
.cardImage {
  height: 305px;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}
.cardContent {
  position: absolute;
  z-index: 10;
  bottom: 0px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  &:hover {
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.86),
      rgba(0, 0, 0, 0)
    );
  }
  @media screen and (max-width: 768px) {
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.86),
      rgba(0, 0, 0, 0)
    );
  }
}

.nameTxt {
  color: #fff;
  font-size: 17px;
  font-family: vars.$Medium;
  text-transform: capitalize;
}
.pricingHead {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pricingTxt {
  color: #fff;
  font-size: 14px;
  font-family: vars.$Medium;
  text-transform: capitalize;
}
.ageTxt {
  color: #fff;
  font-size: 15px;
  font-family: vars.$Medium;
  text-transform: capitalize;
}
.line {
  border-bottom: 1px solid #21b3a8;
  margin-top: 5px;
  margin-bottom: -3px;
}

.clearBtn {
  width: 100%;
  height: 45px;
  background-color: #d3d3d3;
  border-radius: 8px;
  color: #000;
  font-size: 16px;
  font-family: vars.$SemiBold;
  border: none;
}
.countBtnHead {
  cursor: pointer;
  display: flex;
  gap: 4px;
}
.count {
  background-color: #188a8a;
  color: #ffffff;
  width: 30px;
  height: 30px;
  border-radius: 60px;
  font-size: 13px;
  font-family: vars.$SemiBold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.label {
  font-family: vars.$Medium;
  font-size: 14px;
  margin-bottom: 2px;
  margin-left: 2px;
  color: white;
  text-transform: capitalize;
}

.totalAmtHead {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 10px;
}
.totalAmtSubHead {
  background-color: #fff;
  width: 32%;
  border: 1px solid green;
  padding: 10px;
  border-radius: 4px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.formContainer {
  background-color: #188a8a;
  padding: 5%;
  padding-bottom: 1%;
  border-radius: 10px;
}
.bookingDoneBtn {
  width: 100%;
  background-color: #f39826;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  font-family: vars.$SemiBold;
  border: none;
}
.customForm {
  margin-bottom: 8px;
}
.headingTxt {
  color: #ffff;
  text-align: center;
  font-size: 38px;
  margin-top: 180px;
  font-family: vars.$ExtraBold;
  @media screen and (max-width: 768px) {
    margin-top: 10px;
  }
}
.subHeadingTxt {
  color: #ffff;
  text-align: center;
  font-size: 17px;
  font-family: vars.$Regular;
}
.cardsContainer {
  height: 100%;

  overflow: auto;
  @media (min-width: 768px) {
    height: 120vh;
    overflow: scroll;
  }
}
.Modal_BackgroundColor .ant-modal-content {
  background-color: #f5f5f5;
}
.mobilePayNowBox {
  background-color: #f5f5f5;
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 0px;
  z-index: 10;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  font-family: vars.$SemiBold;
}
.mainBox {
  background-color: #188a8a;
}
.Summarycount {
  background-color: #188a8a;
  color: #ffffff;
  width: 25px;
  height: 25px;
  border-radius: 60px;
  font-size: 13px;
  font-family: vars.$SemiBold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.summaryBBox {
  border-radius: 10px;
  padding: 15px;
  background-color: #f5f5f5;
  gap: 20px;
  @media screen and (max-width: 576px) {
    padding: 2px;
  }
}
.summaryBBox2 {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  overflow: scroll;
  height: 50vh;
  @media screen and (max-width: 576px) {
    height: 40vh;
  }
}
.SummaryCard_Footer {
  font-size: 10px;
  margin: 5px 0px 5px;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
}
.summaryHead {
  font-family: vars.$Bold;
  font-size: 17px;
  text-align: center;
  margin: 10px 0px 10px;
}
.summaryBox_sec {
  margin: 10px 0px 10px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.summarySubhead {
  font-size: 12px;
  font-family: vars.$Regular;
  display: flex;
  justify-content: space-between;
}
.summaryKey {
  font-family: vars.$SemiBold;
  font-size: 16px;
}
.summaryValue {
  padding: 2px 0px 2px;
  font-family: vars.$Regular;
  font-size: 12px;
}
.single-pricing {
  text-align: center;
  padding: 10px;
  background-color: red;
}

.multiple-pricing {
  display: flex;
  flex-direction: column;
}
