@use "../../config/variables.scss" as vars;
.Offer_box1 {
  position: relative;
  transition: transform 0.3s ease;
  cursor: pointer;
  @media screen and (min-width: 576px) {
    &:hover {
      transform: scale(1.05);
      transition: transform 0.3s ease;
    }
  }
}
.Offer_image1 {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
  @media screen and (max-width: 576px) {
    height: 200px;
    margin: 0px 5px 0px;
  }
}
.Offer_image2 {
  width: 120px;
  height: 100px;
  background-repeat: no-repeat !important;
  position: absolute;
  background: url("../../assets/images/OfferTag.png");
  background-size: contain;
  transform: rotate(-8.12deg);
  bottom: -13%;
  right: 0%;
}
.Offer_Tagtext {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: vars.$Bold;
  font-size: 20px;
  margin-top: 33px;
  color: vars.$white;
}
.OfferModal_image1 {
  object-fit: cover;
  position: relative;
  width: 100%;
  border-radius: 5px;
  height: 350px;
}
.OfferModal_image2 {
  object-fit: contain;
  position: absolute;
  width: 120px;
  bottom: 28%;
  right: 5%;
}

.Offer_text1 {
  font-size: 18px;
  margin: 15px 0px 3px;
  font-family: vars.$SemiBold;
  cursor: default;
}
.Offer_text2 {
  font-size: 15px;
  cursor: default;
  font-family: vars.$Medium;
}
.Offer_text3 {
  font-size: 13px;
  font-family: vars.$SemiBold;
  cursor: default;
  color: #828583;
}
.Offer_text4 {
  font-size: 8px;
}
.OfferModal_Botton1 {
  color: #1a9692;
  border: 1px solid #1a9692;
}
