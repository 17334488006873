@use "../../config/variables.scss" as vars;

.report-summary {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.report-txt1 {
  color: #000;
  font-size: 14px;
  font-family: vars.$Medium;
}

.report-txt2 {
  color: #000;
  font-size: 14px;
  font-family: vars.$Bold;
}

.report-headtd {
  background-color: vars.$tertiary !important;
  color: #fff !important;
  font-family: vars.$Bold;
  font-size: 20px;
}

.report-titletd {
  height: 50px;
  display: flex;
  align-items: center;
  font-family: vars.$Bold;
}

.report-sumtd {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 20px;
  font-family: vars.$Bold;
}

.report-subtable {
  table-layout: fixed;
}

.report-Box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.report-text1 {
  font-family: vars.$SemiBold;
  color: grey;
  font-size: 14px;
}

.report-text2 {
  font-family: vars.$Bold;
  font-size: 25px;
}

.report-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.title1 {
  font-size: 20px;
  font-family: vars.$SemiBold;
  color: #000;
  margin-bottom: 5px;
}
.table-view {
  color: rgb(63, 62, 62);
  text-decoration: underline;
  cursor: pointer;
  font-family: vars.$SemiBold;
  &:hover {
    color: blue;
  }
}
