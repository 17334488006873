* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* scroll-behavior: smooth; */
  -webkit-font-smoothing: antialiased;
  list-style: none;
}

::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: "DMSans-Bold";
  src: url("./assets/fonts/DMSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-ExtraBold";
  src: url("./assets/fonts/DMSans-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-SemiBold";
  src: url("./assets/fonts/DMSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-Regular";
  src: url("./assets/fonts/DMSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-Medium";
  src: url("./assets/fonts/DMSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-Italic";
  src: url("./assets/fonts/DMSans-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Light";
  src: url("./assets/fonts/Montserrat-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("./assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-ExtraBold";
  src: url("./assets/fonts/Montserrat-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Black";
  src: url("./assets/fonts/Montserrat-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Mistrully";
  src: url("./assets/fonts/Mistrully.ttf") format("truetype");
}

@font-face {
  font-family: "BarlowCondensed-Bold";
  src: url("./assets/fonts/BarlowCondensed-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Barlow-Regular";
  src: url("./assets/fonts/Barlow-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Barlow-Bold";
  src: url("./assets/fonts/Barlow-Bold.ttf") format("truetype");
}



.overFlow-Box2::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.table-Action {
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-Txt {
  font-family: "DMSans-SemiBold";
  font-size: 12px;
  text-transform: capitalize;
}

.table-item {
  display: flex;
}