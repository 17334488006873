@use "./config/variables.scss" as vars;

.screenBox {
  padding: 20px;
}

.LayoutBox {
  min-height: 100vh;
  padding-top: 11vh;
  font-family: vars.$Regular;
}

.heading_h3 {
  font-family: vars.$Bold;
}

.heading_bredcume {
  color: grey;
  font-size: 13px;
  margin-bottom: 20px;
}

.formLabel {
  font-family: vars.$Medium;
  font-size: 14px;
  margin-bottom: 2px;
  margin-left: 2px;
  color: grey;
  text-transform: capitalize;
}
.table-Action {
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table-Txt {
  font-family: vars.$SemiBold;
  font-size: 12px;
  text-transform: capitalize;
}

.table-Txt1 {
  font-family: vars.$SemiBold;
  font-size: 12px;
}
.table-item {
  display: flex;
}
.table-th {
  // background-color: #08493e !important;
  // color: #ffff !important;
  text-align: center;
}

.refreshBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}
