@use "../../config/variables.scss" as vars;
.Resort_text1 {
  color: vars.$tertiary;
  font-family: vars.$SemiBold;
  font-size: 20px;
}
.Resort_text2 {
  font-family: vars.$SemiBold;
  font-size: 15px;
  margin: 5px 0px;
}
