@use "../../config/variables.scss" as vars;

.activityItem {
  min-height: 100px;
  min-height: 100px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-radius: 10px;
  background-color: vars.$light;
}

.activityItem-txt1 {
  font-family: vars.$SemiBold;
  font-size: 20px;
  text-transform: capitalize;
  @media only screen and (max-width: 567px) {
    font-size: 16px;
  }
}

.activityItem-txt2 {
  font-family: vars.$Medium;
  font-size: 13px;
  text-transform: capitalize;
  @media only screen and (max-width: 567px) {
    font-size: 12px;
  }
}

.activityItem-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 10px;
}

.activityItem-box1 {
  flex: 1;
  margin-left: 10px;
}

.activityItem-box2 {
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.stickyBox {
  position: -webkit-sticky;
  position: sticky;
  top: 120px;
}

.summaryBox2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-top: 1px solid grey;
}

.ticket-summaryBox {
  display: flex;
  flex-direction: column;
  height: 75vh;
  overflow: scroll;
  @media only screen and (max-width: 567px) {
    height: 300px;
  }
}

.selectedItem {
  border-bottom: 1px solid #d9d9d9;
  padding: 10px;
  margin-bottom: 15px;
}

.selectedItemTxt1 {
  font-family: vars.$SemiBold;
  font-size: 20px;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selectedItem2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.selectedItemTxt2 {
  font-family: vars.$Medium;
  font-size: 14px;
  text-transform: capitalize;
}
.countBtnHead {
  cursor: pointer;
  display: flex;
  gap: 4px;
}
.Summarycount {
  background-color: #188a8a;
  color: #ffffff;
  width: 25px;
  height: 25px;
  border-radius: 60px;
  font-size: 13px;
  font-family: vars.$SemiBold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.summaryCol {
  display: block;
  @media only screen and (max-width: 567px) {
    display: none;
  }
}
.summaryMobCol {
  display: none;
  @media only screen and (max-width: 567px) {
    display: block;
    position: fixed;
    bottom: 0;
    background: #ffff;
    padding: 10px;
  }
}
.summaryMobHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.clearAct {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.ticketDetTxtMob {
  font-size: 11px;
  color: #2020b2;
  font-family: vars.$Regular;
  cursor: pointer;
  & :hover {
    text-decoration: underline;
  }
}
.item {
  display: flex;
  align-items: center;
  border: 1px solid vars.$tertiary;
  margin-bottom: 15px;
  border-radius: 5px;
}
.item2 {
  display: flex;
  align-items: "center";
  border-color: vars.$tertiary;
  border-left-width: 1;
}
.btn1 {
  border-left: 1px solid vars.$tertiary;
  border-right: 1px solid vars.$tertiary;
  padding: 10px;
}
.btn2 {
  width: 65px;
  display: flex;
  justify-content: center;
  border-right: 1px solid #188a8a;
  align-items: center;
}
.btn3 {
  background-color: vars.$tertiary;
  padding: 10px;
}
.itemtxt {
  font-family: vars.$Bold;
  flex: 1;
  color: "#000";
  margin-left: 10px;
  text-transform: "capitalize";
  font-size: 15px;
}
.btn2txt {
  font-family: vars.$Bold;
  color: "#000";
}
