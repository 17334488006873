@use "../../config/variables.scss" as global;
.Header {
  height: 25vh;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background: url("../../assets/images/navbar-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.Header-scrolled {
  height: 11vh;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  padding-top: 5px;
  cursor: pointer;
}

.Header-scrolled2 {
  height: 11vh;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  cursor: pointer;
  padding-top: 5px;
  background-color: #fff;
  border-bottom: 1px solid rgb(222, 222, 222);
}

.Header-Container {
  max-height: 11vh;
}

.Header-Box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.Header-logo {
  height: 100px;
  width: 180px;
  object-fit: contain;
  @media screen and (max-width: 576px) {
    width: 120px;
    height: 80px;
  }
  &.scrolled {
    height: 65px;
    @media screen and (max-width: 576px) {
      height: 58px;
      width: 100px;
      margin-top: -5px;
    }
  }
}

.Header-Box2 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 25px;
  padding-top: 6px;
  @media screen and (max-width: 576px) {
    display: none;
  }
}

.Header-items {
  height: 100%;
  color: global.$tertiary;
  font-family: global.$Bold;
  font-size: 16px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  &.a {
    text-decoration: none;
  }
  &:hover {
    border-bottom: 2px solid global.$tertiary;
    transition: border-width 0.6s linear;
  }
}

.Header-ticket {
  width: 180px;
  height: 35px;
  cursor: pointer;
  object-fit: contain;
  @media screen and (max-width: 576px) {
    display: none;
  }
}

.Header-mobileMenu {
  display: none;
  height: 80px;
  color: global.$tertiary;
  @media screen and (max-width: 576px) {
    display: flex;
    align-items: center;
    height: 60px;
  }
}

.CustomDrawer-blurry {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  z-index: 999;
  background-color: #033b1c78;
}

.CustomDrawer-Header {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  height: 12vh;
  padding: 10px 15px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.156);
}

.CustomDrawer-close {
  font-size: 40px;
  color: #fff;
}

.CustomDrawer-container {
  padding: 25px;
}

.CustomDrawer-item {
  color: #fff;
  font-family: global.$Bold;
  margin-bottom: 20px;
}

.CustomDrawer-ticketBox {
  display: flex;
  align-items: center;
}
.Drawer-text1 {
  color: #fff;
  font-size: 20px;
  margin-bottom: 10px;
  font-family: global.$SemiBold;
}
.Drawer-text2 {
  color: #fff;
  font-size: 16px;
  padding-bottom: 20px;
  text-align: left;
  cursor: pointer;
  text-decoration: none;
  font-family: global.$SemiBold;
}
