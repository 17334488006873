@use "../../config/variables.scss" as vars;
.PageHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-bottom: 5px;
  padding-left: 20px;
  border-bottom: 1px solid rgb(209, 209, 209);
  background-color: #fff;
  padding-right: 20px;
}

.PageHeaderBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.PageHeader-back {
  font-size: 30px;
  border-right: 1px solid rgb(186, 186, 186);
  padding-right: 20px;
  margin-right: 20px;
  cursor: pointer;
  &:hover {
    background-color: rgb(240, 240, 240);
  }
}

.PageHeader-Box {
  font-family: vars.$Regular;
}

.PageHeader-text1 {
  font-size: 20px;
  font-family: vars.$SemiBold;
}
