@use "../../../../config/variables.scss" as global;
.climbZipBg {
  display: none;
  @media screen and (min-width: 992px) {
    display: block;
    background: url("../../../../assets/images/climbZip3.png");
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }
}
.climbZipBgSm {
  display: none;
  @media screen and (max-width: 992px) {
    display: block;
  }
}
.mountainTextBox {
  width: 70%;
  @media screen and (max-width: 1250px) {
    width: 100%;
  }
}
.flyTextBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: end;
  width: 80%;
  margin: 0px 0px 50px 70px;
}
.fancytxtEnjoy {
  font-family: global.$fancy;
  font-size: 150px;
  width: 50%;
  color: global.$primary1;
  text-align: center;
  transform: rotate(-11.12deg);
  margin: 0px 0px -20px -10px;
  @media screen and (max-width: 786px) {
    font-size: 75px;
    width: 70%;
    margin: 20px 0px -25px 40px;
  }
}
.fancytxtFly {
  font-family: global.$fancy;
  font-size: 150px;
  width: 50%;
  color: global.$primary1;
  text-align: center;
  transform: rotate(-11.12deg);
  margin: 0px 0px -20px -10px;
  @media screen and (max-width: 786px) {
    font-size: 75px;
    width: 70%;
    margin: 20px 0px -25px 40px;
  }
}
.fancytxt1Sm {
  font-family: global.$fancy;
  font-size: 75px;
  color: global.$primary1;
  text-align: start;
  transform: rotate(-11.12deg);
  margin-left: 13px;
  text-shadow:
    -2px -2px 0 white,
    2px -2px 0 white,
    -2px 2px 0 white,
    2px 2px 0 white;
  @media screen and (min-width: 786px) {
    font-size: 150px;
  }
}
.upperHeadTxt {
  font-family: global.$BarlowCondensedBold;
  color: global.$tertiary;
  margin: 10px 0px 5px;
  font-size: 30px;
  text-align: center;
  @media screen and (min-width: 992px) {
    font-size: 50px;
    text-align: start;
  }
}
.headerTxtMain {
  font-size: 41px;
  font-family: global.$BarlowCondensedBold;
  color: global.$tertiary;
  margin-top: -25px;
  text-align: center;
  @media screen and (min-width: 992px) {
    font-size: 75px;
    margin-top: -30px;
    text-align: start;
  }
}
.desc {
  font-size: 16px;
  text-align: left;
  font-family: global.$BarlowRegular;
  @media screen and (max-width: 992px) {
    font-size: 12px;
    width: 80%;
    color: global.$secondary1;
    line-height: 23px;
    text-align: start;
    color: #ede5bd;
  }
  @media screen and (min-width: 1400px) {
    width: 60%;
  }
}
.descEnjoy {
  text-align: left;
  font-family: global.$BarlowRegular;
  font-size: 18px;
  width: 90%;
  color: global.$secondary1;
  line-height: 23px;
  @media screen and (min-width: 1800px) {
    line-height: 30px;
    font-size: 23px;
  }
}
.descSm {
  padding: 5px 30px 5px 30px;
  font-size: 12px;
  font-family: global.$BarlowRegular;
  line-height: 23px;
  color: #000000;
}
.blankDiv {
  display: none;
  @media screen and (min-width: 992px) {
    display: block;

    height: 100px;
  }
  @media screen and (min-width: 1250px) {
    height: 70vh;
  }
  @media screen and (min-width: 2000px) {
    height: 90vh;
  }
}
