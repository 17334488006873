@use "../../config/variables.scss" as vars;

.card-details {
  margin-top: 20px;
  margin-bottom: 20px;
}
.heading-txt {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}
.white-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.indicatorMainHead {
  width: 100%;
  display: flex;
  justify-content: center;
}
.currIndHead {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.oldIndHead {
  display: flex;
  align-items: center;
}
.currAssi-Indicator {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #d1e7dd;
  margin-right: 5px;
}
.oldAssi-indicator {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #f8d7da;
  margin-right: 5px;
}

.pagination-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.table-td {
  background-color: red;
}

.table-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.header-txt1 {
  font-size: 18px;
  font-family: vars.$SemiBold;
  color: rgb(0, 0, 0);
}
