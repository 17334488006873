.Home-Section1 {
  display: flex;
  align-items: center;
  position: relative;
}

.Home-image1 {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  @media screen and (max-width: 576px) {
    height: 50vh;
  }
}
.Section1_ticket {
  width: 300px;
  height: 70px;
  cursor: pointer;
  bottom: 8%;
  position: absolute;
  @media screen and (max-width: 576px) {
    width: 200px;
    height: 45px;
  }
}
