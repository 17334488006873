.Loading-box {
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img{
    height: 100px;
  }
}
