@use "../../config/variables.scss" as vars;

.pageHeaderConatiner {
  width: 100%;
  height: 70px;
  background-color: white;
  // display: flex;
  // align-items: center;
}
.headerTitle {
  font-family: vars.$Bold;
  font-size: 24px;
  padding-left: 20px;
}
