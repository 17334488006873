@use "../../config/variables.scss" as global;
.About_text3 {
  font-family: global.$Regular;
  font-size: 18px;
}
.About_text4 {
  font-family: global.$Bold;
  font-size: 48px;
  color: global.$tertiary;
  line-height: 56px;
  text-align: center;
}
.About_text5 {
  font-family: global.$Bold;
  font-size: 25px;
}
