.booking-summary-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 0 20px;
}

.booking-summary {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.main-heading {
  font-family: "Arial", sans-serif;
  font-size: 24px;
  font-weight: bold;
  color:black;
}

.section-heading {
  font-family: "Arial", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.booking-summary p {
  font-family: "Arial", sans-serif;
  font-size: 16px;
  color: white;
  margin: 10px 0;
}

.booking-details,
.activity-details,
.payment-details {
  border-top: 1px solid #ccc;
  padding-top: 20px;
}

.payment-details {
  margin-top: 20px;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .booking-summary-container {
    padding: 0 10px; /* Adjust padding for smaller screens */
  }
  
  .main-heading {
    font-size: 20px;
  }
  
  .section-heading {
    font-size: 18px;
  }
  
  .booking-summary p {
    font-size: 14px;
  }
}
