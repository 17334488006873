@use "../../config//variables.scss" as vars;

.td-style {
  font-weight: bold;
  padding-right: 10px;
  font-size: 20px;
  border: 2px solid black;
  // border-bottom-width: 4px;
}

.tb-style {
  width: 100%;
  height: auto;
}

.card-style {
  width: 80%;
  height: auto;
}
.jobScreen-PageHeaderBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.totalItem {
  display: "flex";
  flex: 1;
  flex-direction: "row";
  align-items: "center";
  border-color: "#f2f2f2";
  border-width: 1;
  padding: 10;
  border-radius: 10;
  margin-bottom: 5;
  margin-top: 5;
}
.totalItemtxt1 {
  font-family: "DMSans-SemiBold";
  flex: 1;
  font-size: 10;
  color: "grey";
  text-transform: capitalize;
}
.totalItemtxt2 {
  font-family: "DMSans-SemiBold";
  flex: 1;
  font-size: 20;
  color: "#000";
  text-align: "right";
  text-transform: capitalize;
}

.jobTxt1 {
  font-size: 15px;
  font-family: vars.$SemiBold;
  color: grey;
}

.jobTxt2 {
  font-size: 20px;
  font-family: vars.$SemiBold;
  color: #000;
}
.popoverContentTxt {
  color: orange;
  font-family: "DMSans-SemiBold";
}
