@use "../../config/variables.scss" as vars;

.cardBox {
  padding: 15px;
  border-radius: 15px;
  background: linear-gradient(300deg, #46d373, #018989, #08493e);
  cursor: pointer;
  transition: transform 0.3s ease;
  width: 100%;
}
.cardBoxInner {
  display: flex;
  align-items: center;
  font-family: vars.$Bold;
}

.cardtxt1 {
  font-family: vars.$Bold;
  font-size: 25px;
  color: #fff;
}

.cardtxt2 {
  font-size: 14px;
  margin-left: 5px;
  color: #fff;
  font-family: vars.$SemiBold;
}

.cardtxt3 {
  font-size: 14px;
  color: #fff;
  margin-left: 5px;
  margin-top: 10px;
  font-family: vars.$SemiBold;
}

.cardtxt4 {
  margin: 0;
  font-size: 25px;
  color: black;
  font-family: vars.$Bold;
}

.cardtxt5 {
  color: red;

  font-family: vars.$SemiBold;
  font-size: 17px;
}

.cardtxt6 {
  font-size: 15px;
  margin-left: 5px;
  margin: 0;
  color: #fff;
  text-align: right;
}

.cardtxt7 {
  margin-left: 5px;
  color: orange;
  font-family: vars.$SemiBold;
}

.cardLogo {
  width: 70px;
}
.hd-text {
  font-family: vars.$Bold;
  font-size: 20px;
}

.for-card-text-margin {
  margin-right: 3px;
}

// ticket item

.main-class {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  gap: 25px;
  background-color: #e9f5ec;
  margin-bottom: 8px;
}

.for-icon {
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.for-end-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sub-class {
  display: flex;
}
.for-use-used {
  background-color: red;
  color: white;
  text-align: center;
  width: 55px;
  border-radius: 6px;
  font-size: 10px;
  margin-top: 4px;
  font-weight: 600;
}
.for-use-active {
  background-color: green;
  color: white;
  text-align: center;
  width: 55px;
  border-radius: 6px;
  font-size: 10px;
  margin-top: 4px;
  font-weight: 600;
}
.bold-text {
  font-family: vars.$ExtraBold;
  font-size: 13px;
}
.normal {
  font-family: vars.$Regular;
  color: rgb(92, 92, 92);
  font-size: 15px;
}
.ticket-color {
  color: green;
  font-family: vars.$ExtraBold;
  text-transform: uppercase;
}
.for-padding-for-all-text {
  padding: 2px;
}
.referenceCard {
  // display: flex;
  padding: 16px;
  border-radius: 10px;
  width: 100%;
  background-color: #e9f5ec;
}
.emptyBox {
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
