@use "../../config/variables.scss" as vars;
.ShareBox {
  border: 1px solid rgb(228, 228, 228);
  padding: 10px;
  height: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  font-family: vars.$Bold;
  cursor: pointer;
}
.ShareBox-img {
  height: 80px;
  margin-bottom: 20px;
}
.ShareBox-txt1 {
  margin-top: 10px;
  text-decoration: none;
}
