@use "../../../../config/variables.scss" as global;
.fancytxt1 {
  font-family: global.$fancy;
  font-size: 130px;
  color: global.$primary1;
  text-align: center;
  transform: rotate(-11.12deg);
  margin: 0px 0px -25px -15px;
  @media screen and (max-width: 992px) {
    font-size: 75px;
    width: 70%;
    margin: 20px 0px -25px 40px;
    text-align: center !important;
  }
}

.fancytxt2 {
  font-family: "Mistrully";
  font-size: 150px;
  color: #42d3e1;
  transform: rotate(-11.12deg);
  margin-left: 19px;
  margin-bottom: -40px;
  text-align: center;
  @media screen and (max-width: 992px) {
    font-size: 75px;
    width: 70%;
    margin: 20px 0px -25px 40px;
    text-align: center !important;
  }
}
.fancytxt3 {
  font-size: 150px;
  text-align: center;
  margin: 10px 0px -18px 19px;
  color: global.$primary1;
  font-family: global.$fancy;
  transform: rotate(-11.12deg);
  @media screen and (max-width: 992px) {
    font-size: 75px;
    width: 70%;
    margin: 20px 0px -25px 40px;
    text-align: center !important;
  }
}
.upperHeadTxt {
  font-family: global.$BarlowCondensedBold;
  color: global.$tertiary;
  margin: 10px 0px 5px;
  font-size: 30px;
  text-align: center;
  @media screen and (min-width: 992px) {
    font-size: 50px;
    text-align: start;
  }
}
.headerTxtMain {
  font-size: 41px;
  font-family: global.$BarlowCondensedBold;
  color: global.$tertiary;
  margin-top: -25px;
  text-align: center;
  @media screen and (min-width: 992px) {
    font-size: 75px;
    margin-top: -30px;
    text-align: start;
  }
}

.cardHead {
  font-size: 30px;
  color: global.$secondary1;
  font-family: global.$BarlowBold;

  margin-top: 10px;
  @media screen and (max-width: 768px) {
    font-size: 17px;
  }
  @media screen and (max-width: 580px) {
    font-size: 14px;
  }
  @media screen and (max-width: 992px) {
    font-size: 18px;
  }
}
.cardCntainer {
  padding: 50px;
  margin-inline: 0;
  @media screen and (max-width: 580px) {
    padding: 16px !important;
  }
}

.textBoxMobile {
  display: none;
  @media screen and (max-width: 992px) {
    display: block;
  }
}
.textBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 992px) {
    display: none;
  }
}
.enjoyImageParent {
  background: url("../../../../assets/images/bro.png");
  width: 100%;
  height: 100vh;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  @media screen and (max-width: 992px) {
    background: url("../../../../assets/images/brother-sister-having-fun-Mobile.png");
    height: 35vh;
    background-size: contain !important;
  }
}
.waterImageParent {
  background: url("../../../../assets/images/pool.jpg");
  width: 100%;
  height: 120vh;
  background-position: center;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  @media screen and (max-width: 992px) {
    background: none;
    height: 100%;
  }
}
.boatImageParent {
  background: url("../../../../assets/images/boat.jpg");
  width: 100%;
  height: 120vh;
  background-position: center;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  @media screen and (max-width: 992px) {
    background: none;
    height: 100%;
  }
}
.trainImageParent {
  background: url("../../../../assets/images/train.jpg");
  width: 100%;
  height: 100vh;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  @media screen and (max-width: 992px) {
    background: none;
    height: 100%;
  }
}
.cafeImageParent {
  background: url("../../../../assets/images/cafe.12.jpg");
  width: 100%;
  height: 150vh;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  @media screen and (max-width: 992px) {
    background: none;
    height: 100%;
  }
}
.craftImageParent {
  background: url("../../../../assets/images/handcraft.jpg");
  width: 100%;
  height: 140vh;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  @media screen and (max-width: 992px) {
    background: none;
    height: 100%;
  }
}
