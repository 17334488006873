@use "../../config/variables.scss" as vars;

.TicketInvoice-box1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.TicketInvoice-txt1 {
  padding: 10px;
  font-family: vars.$SemiBold;
}
.TicketInvoice-Downlaod-Btn {
  padding: 20px 30px;
  width: 100%;
  text-align: end;
  margin: 0px 0px 10px 0px;
}
